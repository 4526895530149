<template>
  <div v-html="$t('policy.policy')"></div>
</template>
<script>
export default {
  name: "Policy",
  data() {
    return {};
  },
};
</script>
