<template>
  <Policy
    style="
      padding: 50px;
      align-items: center;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    "
  ></Policy>
</template>
<script>
import Policy from "../components/Policy/Policy.vue";
export default {
  components: {
    Policy,
  },
  data() {
    return {};
  },
};
</script>
